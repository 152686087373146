import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../assets/images/logo.svg";
import Modal from "../Modal/Modal";
import "./Header.scss";

const scrollToSection = (id) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

const Header = () => {
  const [active, setActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSection, setCurrentSection] = useState('');

  // Handle scroll and update the current section
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      let current = '';
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          current = section.id;
        }
      });
      setCurrentSection(current);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call once to set initial value

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    setActive(!active);
  };

  const handleNavLinkClick = (id) => {
    scrollToSection(id);
    setActive(false);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col">
              <Link to="/">
                <img src={Logo} className="logo" alt="logo" />
              </Link>
              <div className={active ? "navbar active" : "navbar"}>
                <nav className="nav">
                  <NavLink
                    to="#info"
                    onClick={() => handleNavLinkClick('info')}
                    className={currentSection === 'info' ? 'current' : ''}
                  >
                    Qui sommes-nous
                  </NavLink>
                  <NavLink
                    to="#mission"
                    onClick={() => handleNavLinkClick('mission')}
                    className={currentSection === 'mission' ? 'current' : ''}
                  >
                    Notre mission
                  </NavLink>
                  <NavLink
                    to="#work"
                    onClick={() => handleNavLinkClick('work')}
                    className={currentSection === 'work' ? 'current' : ''}
                  >
                    Comment ça marche
                  </NavLink>
                  <NavLink
                    to="#boostcv"
                    onClick={() => handleNavLinkClick('boostcv')}
                    className={currentSection === 'boostcv' ? 'current' : ''}
                  >
                    Boostez vos CV
                  </NavLink>
                  <NavLink
                    to="#formula"
                    onClick={() => handleNavLinkClick('formula')}
                    className={currentSection === 'formula' ? 'current' : ''}
                  >
                    Nos formules
                  </NavLink>
                </nav>
              </div>
              <div className="menu-icon" onClick={handleClick}>
                <i className={active ? "fa-solid fa-xmark" : "fa-solid fa-bars"}></i>
              </div>
              <button onClick={() => setIsModalOpen(true)}>Déposer un CV</button>
            </div>
          </div>
        </div>
      </header>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default Header;
